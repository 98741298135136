<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.name' placeholder="输入问题名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="问题类型:">
                        <el-select v-model="filters.questionType" placeholder="请选择">
                            <el-option
                                v-for="item in options2"
                                :key="item.ID"
                                :label="item.TypeName"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否推荐:">
                        <el-select v-model="filters.isRecommend" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            @current-change="selectCurrentRow"
            highlight-current-row
            style="width: 100%"
            >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="Name" label="问题名称" align="center" min-width="300"></el-table-column>
            <el-table-column prop="TypeName" label="类型" align="center"></el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" :formatter="formatCreateTime" align="center" min-width="100"></el-table-column>
            <el-table-column prop="IsRecommend" label="是否推荐" align="center" min-width="100">
                <template scope="scope">
                    <el-tag v-if="scope.row.IsRecommend" type="success">是</el-tag>
                    <el-tag v-else type="warning">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="180">
                <template slot-scope="scope">
                    <!-- <el-button @click="handleViews(scope.row)" type="text" size="small">查看</el-button> -->
                    <el-button v-if="!scope.row.IsRecommend" @click="handleRecommend(scope.row)" type="text" size="small">推荐</el-button>
                    <el-button v-else @click="cnacelRecommend(scope.row)" type="text" size="small">取消推荐</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAddOrEdit?'新增问答':'编辑问答'"
        :visible.sync="addFormDialog"
        v-model="addFormDialog"
        :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="100px" enctype="multipart/form-data">
                <el-form-item label="问题类型:" prop='questionType'>
                    <el-select v-model="dialogForm.questionType" placeholder="请选择">
                        <el-option
                            v-for="item in options2"
                            :key="item.ID"
                            :label="item.TypeName"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='问题名称:' prop='name'>
                    <el-input v-model='dialogForm.name'></el-input>
                </el-form-item>
                <el-form-item label='答案:' prop='questionAnswer'>
                    <el-input type="textarea" style="height: 150px;" v-model='dialogForm.questionAnswer'></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getQuestionAnswerInfoListPage,getQuestionAnswerTypeList,addQuestionAnswerInfo,updateQuestionAnswerInfo,deleteQuestionAnswerInfo,recommendQuestionAnswerInfo } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
            filters: {
                name: '',
                isRecommend: '', //是否推荐
                questionType: '',
            },
            buttonList: [],
            options: [
                {value:true,name:'是'},
                {value:false,name:'否'},
            ],
            options2: [],
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            isAddOrEdit:true,
            addFormDialog:false,
            addLoading:false,
            dialogForm:{
                name:'',
                questionAnswer:'',
                questionType: '',
            },
            dialogFormRules:{
                name:[{required:true,message:'请输入问题名称',trigger:'blur'}],
                questionType:[{required:true,message:'请选择问答类型',trigger:'blur'}],
                questionAnswer:[{required:true,message:'请输入问题答案',trigger:'blur'}],
            },
            needEditData: '',
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            this.listLoading = true
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.name){
                params.name = this.filters.name
            }
            if(this.filters.isRecommend !== ''){
                params.isRecommend = this.filters.isRecommend
            }
            if(this.filters.questionType){
                params.qaTypeId = this.filters.questionType
            }
            getQuestionAnswerInfoListPage(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        // 获取问题类型
        getQuestType() {
            getQuestionAnswerTypeList().then(res => {
                var result = res.data
                if(result.Success){
                    this.options2 = result.Response
                }
            })
        },
        // 查询
        getQuestionAnswerInfoListPage() {
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.dialogForm = {
                name:'',
                questionAnswer:'',
                questionType: '',
            }
            this.isAddOrEdit = true;
            this.addFormDialog = true;
        },
        // 编辑
        handleEdit() {
            if(this.needEditData){
                var typeData = ''
                this.options2.forEach((item) => {
                    if(this.needEditData.TypeName == item.TypeName){
                        typeData = item.ID
                    }
                })
                this.dialogForm = {
                    name:this.needEditData.Name,
                    questionAnswer:this.needEditData.AnswerContent,
                    questionType: typeData,
                }
                this.isAddOrEdit = false;
                this.addFormDialog = true;
            }else{
                this.$message({
                    message: '请选择要编辑的数据',
                    type: 'warning'
                });
            }
        },
        // 删除
        handleDel() {
            if(this.needEditData){
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        Id: this.needEditData.ID
                    }
                    deleteQuestionAnswerInfo(params).then((res) => {
                        var result = res.data
                            if(result.Success){
                                this.$message({
                                    message: result.Message,
                                    type: 'success'
                                });
                                this.getListData();
                            }else{
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                            }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除的数据',
                    type: 'warning'
                });
            }
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.needEditData = val
        },
        // 查看
        handleViews() {

        },
        // 推荐
        handleRecommend(val) {
            var params = {
                Id: val.ID
            }
            recommendQuestionAnswerInfo(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                }
            })
        },
        // 取消推荐
        cnacelRecommend(val) {
            var params = {
                Id: val.ID
            }
            recommendQuestionAnswerInfo(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.getListData();
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                }
            })
        },
        // 提交
        addSubmit() {
            this.$refs['dialogFormRef'].validate(valid => {
                if(valid){
                    this.addLoading = true
                    var params = {
                        QuestionAnswerTypeId:this.dialogForm.questionType,
                        AnswerContent:this.dialogForm.questionAnswer,
                        Name:this.dialogForm.name,
                    }
                    if(this.isAddOrEdit){
                        addQuestionAnswerInfo(params).then((res) => {
                            var result = res.data
                            if(result.Success){
                                this.$message({
                                    message: result.Message,
                                    type: 'success'
                                });
                                this.getListData();
                                this.addLoading = false;
                                this.addFormDialog = false;
                            }else{
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                                this.addLoading = false;
                            }
                        })
                    }else{
                        params.Id = this.needEditData.ID
                        updateQuestionAnswerInfo(params).then((res) => {
                            var result = res.data
                            if(result.Success){
                                this.$message({
                                    message: result.Message,
                                    type: 'success'
                                });
                                this.getListData();
                                this.addLoading = false;
                                this.addFormDialog = false;
                            }else{
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                                this.addLoading = false;
                            }
                        })
                    }
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getListData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.getListData();
        this.getQuestType();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>